<template>
  <div>
    <el-main class="com-eTrip-section section-has-footer">
      <SectionContent style="background-color: #fff">
        <com-list-search>
          <el-form :inline="true" size="medium" slot="list-search-before">
            <el-form-item>
              <el-input placeholder="手机号" clearable v-model="form.mobile"/>
            </el-form-item>
            <el-form-item>
              <el-input placeholder="机构名" clearable v-model="form.orgName"/>
            </el-form-item>
            <el-form-item>
              <el-input placeholder="IP" clearable v-model="form.ip"/>
            </el-form-item>
            <el-form-item>
              <el-select v-model="form.isAbnormal" placeholder="是否异常">
                <el-option value="-1" label="全部"></el-option>
                <el-option value="0" label="无异常"></el-option>
                <el-option value="1" label="有异常"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div slot="list-search-after">
            <el-button @click="resetClick()">重置</el-button>
            <el-button type="primary" @click="getList()">搜索</el-button>
          </div>
        </com-list-search>
        <el-table style="width: 100%;" border v-loading="loading" :data="getData">
          <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
          <el-table-column prop="loginName" label="用户名" align="center"></el-table-column>
          <el-table-column prop="mobile" label="手机号" align="center"></el-table-column>
          <el-table-column prop="orgName" label="机构名称" align="center"></el-table-column>
          <el-table-column prop="ip" label="IP" align="center"></el-table-column>
          <el-table-column prop="port" label="端口" align="center"></el-table-column>
          <el-table-column prop="createTime" label="登录时间" align="center">
            <template slot-scope="scope">
              {{ scope.row.createTime | dateCus}}
            </template>
          </el-table-column>
          <el-table-column prop="isAbnormal" label="是否异常" align="center">
            <template slot-scope="scope">
              <el-tag :type="scope.row.isAbnormal===1?'danger':'primary'">
                {{ scope.row.isAbnormal === 1 ? '异常' : '无' }}
              </el-tag>
            </template>
          </el-table-column>
        </el-table>
      </SectionContent>
    </el-main>
    <el-footer style="display: flex;justify-content: flex-end;align-items: center">
      <el-pagination background layout="prev, pager, next"
                     :current-page="form.pageIndex"
                     :page-size="form.pageSize"
                     :total="result.total"
                     @current-change="getList">
      </el-pagination>
    </el-footer>
  </div>
</template>

<script>
import comListSearch from "@/www/components/comListSearch/index.vue";
import SectionContent from "@/components/sectionContent/index.vue";
import {combinationForm} from "@/common/js/common";
import {tdsLoginLogListApi} from "@/www/urls/zslManage";

const createForm = (form = {}) => ({
  pageIndex: 1,
  pageSize: 10,
  ...form
})

export default {
  name: "TDSLoginLog",
  data() {
    return {
      loading: false,
      form: createForm(),
      result: {
        data: [],
        total: 0
      }
    }
  },
  components: {
    comListSearch,
    SectionContent,
  },
  mounted() {
    this.getList();
  },
  activated() {
    if (this.$route.query.t !== this._t) {
      this._t = this.$route.query.t;
      this.getList();
    }
  },
  computed: {
    getData() {
      return this.result.data.map((item) => {
        let orgName = item.orgName;
        try {
          orgName = JSON.parse(item.orgName)
        } catch (err) {
          console.log(err);
        }
        return {
          ...item,
          orgName: typeof orgName === 'object' ? orgName.join('、') : ''
        }
      })
    }
  },
  methods: {
    async getList(page = 1) {
      this.form.pageIndex = page;
      this.loading = true;
      const ret = await tdsLoginLogListApi(combinationForm({
        ...this.form,
        isAbnormal: this.form.isAbnormal === -1 ? undefined : this.form.isAbnormal
      }))
      this.loading = false
      if (ret.success) {
        this.result = ret.data;
      } else this.$message.error(ret.errors[0].message)

    },
    resetClick(form) {
      this.form = createForm(form);
      this.getList(1)
    },
    async handleChange() {
      this.form.pageIndex = 1;
      this.resetClick({
        signatureYear: this.form.signatureYear
      });
    }
  }
}
</script>

<style scoped lang="scss">
.eTrip-section-cont {
  margin-top: 10px;
}

.message-info-btn {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
